@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UpdateBannerForm {
	width: 100%;
	height: auto;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_created {
			font-size: 1.6rem;
			font-weight: 600;
			margin-top: 2rem;

			& > b {
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}

	&_community {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 0.5rem;

		& > b {
			color: map-get($map: $blueMap, $key: "600");
		}
	}
	&_communityID {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");

		& > b {
			font-weight: 500;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_form {
		width: 100%;
		height: auto;
		padding: 2rem 2rem;
		border-radius: 0.5rem;
		// border: none;

		&_item {
			width: auto;
			// font-size: 1.6rem;
		}
	}

	&_selector {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;
	}
	&_form {
		width: 100%;

		&_item {
			width: auto;
			margin: 2rem 0;
		}
	}
	&_actions {
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 4rem;
		@include flex_row(flex-end, center);
	}
}
