@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsActions {
	width: 100%;
	@include flex_row(flex-end, flex-start);
	margin-top: 1rem;
	margin-bottom: 6rem;
}
