@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsTableRow {
	width: 100%;
	height: 5rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	@include flex_row(space-evenly, center);
	&:hover {
		cursor: pointer;
		background-color: #eeeeee;
		border: 1px solid map-get($map: $blueMap, $key: "600");
		transform: scale(1.05);
	}

	&_cell {
		width: calc(100% / 6);
		height: 100%;
		@include flex_row(center, center);
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		font-family: map-get($map: $fonts, $key: "mainSans");
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}
}
