@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RenameFacility {
	width: max-content;

	&_warning {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		color: map-get($map: $redMap, $key: "600");
		font-size: 1.4rem;
		font-weight: 500;
	}

	&_actions {
		margin-top: 4rem;
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
