@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LogoutButton {
	position: fixed;
	top: 1rem;
	right: 3.5rem;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	border: none;
	outline: none;
	background-color: none;
	@include flex_col(center, center);
	&:hover {
		background-color: #fff;
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavySubtle");
	}

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $colors, $key: "mainRed");
	}
	&_text {
		font-size: 1.3rem;
		font-weight: 600;
		color: map-get($map: $colors, $key: "mainRed");
	}
}

@media only screen and (max-width: 650px) {
	.LogoutButton {
		width: 4rem;
		height: 4rem;

		&_icon {
			width: 1.8rem;
			height: 1.8rem;
			fill: map-get($map: $colors, $key: "mainRed");
		}
		&_text {
			font-size: 1.1rem;
			font-weight: 600;
			color: map-get($map: $colors, $key: "mainRed");
		}
	}
}
