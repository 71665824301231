@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SuspendView {
	@include fill_container;
	min-height: 60rem;
	padding-bottom: 5rem;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
		}
		&_message {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
		}
	}

	&_unsuspend {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, flex-start);

		&_btn {
			border: none;
			outline: none;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");
			&:hover {
				cursor: pointer;
			}
		}
	}

	&_main {
		@include fill_container;

		&_warning {
			width: 100%;
			margin: 2rem 0;
		}
	}
	&_actions {
		width: 100%;
		padding: 2rem;
		@include flex_row(flex-end, center);
	}
}
.WarningMessage {
	width: 100%;

	&_facility {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
		margin-bottom: 1rem;
		& > b {
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_reason {
		font-size: 1.6rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");
		margin-bottom: 1rem;
		& > b {
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_directions {
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
		margin: 2rem 0;
	}
}
