@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DisableFacilityAppAccessView {
	@include fill_container;

	&_facility {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");

			& > b {
				color: map-get($map: $blueMap, $key: "600");
				font-weight: 800;
				font-family: map-get($map: $fonts, $key: "altSans");
			}
		}
	}

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
		}
		&_subtitle {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "600");
		}
	}

	&_access {
		width: 50rem;
		height: auto;
		padding: 2rem;
		background-color: #ffffff;
		border-radius: 0.5rem;
		margin-bottom: 2rem;
	}
}
