@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ImportResidents {
	width: 100%;
	@include flex_col(center, center);

	&_header {
		width: 100%;
		margin-bottom: 5rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
			text-align: center;
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 500;
			text-align: center;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_info {
		font-size: 1.3rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 3rem;
		font-family: "Open Sans";

		& b {
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
	&_extra {
		margin-top: 4rem;
		width: auto;
	}
}
