@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ToggleUIView {
	@include fill_container;

	&_label {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		margin-bottom: 1rem;

		& > b {
			color: map-get($map: $blueMap, $key: "600");
		}
	}
	&_warning {
		margin-top: 4rem;
		font-size: 1.5rem;

		&_link {
			color: map-get($map: $orangeMap, $key: "500");
		}
		&_text {
			margin-top: 2rem;
			font-size: 1.8rem;
			color: map-get($map: $redMap, $key: "600");
		}
	}
}
