@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MakeIndependentFacility {
	@include fill_container;

	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
