@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserDropdown {
	width: 100%;
	height: auto;
	min-height: 16rem;
	@include flex_col(center, center);
	border: 1px solid map-get($map: $blueGreyMap, $key: "400");
	border-radius: 0.5rem;

	& label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}
