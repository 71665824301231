@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FlushCacheView {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_title {
			font-size: 3rem;
			font-weight: 600;
		}
	}
}
