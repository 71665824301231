@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateAccountStatus {
	width: 50rem;
	max-width: 100vw;
	height: 40rem;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -25rem;
	margin-top: -20rem;
	background-color: #ffffff;
	border-radius: 1rem;
	box-shadow: 100px 100px 400px 834px rgba(0, 0, 0, 0.82);
	z-index: 100;

	&_close {
		width: 100%;
		height: auto;
		padding: 0.3rem 0.5rem;
		@include flex_row(flex-end, flex-start);

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $redMap, $key: "600");
			cursor: pointer;
		}
	}

	&_status {
		width: 100%;
		height: 70%;
		@include flex_col(center, center);
		padding: 2rem;
		padding-bottom: 4rem;
	}

	&_success {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}
	&_newAccountID {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}

	&_failed {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "700");
	}
	&_failedMsg {
		font-size: 1.7rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "500");
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(center, center);

		&_btn {
			padding: 1.2rem 1.8rem;
			border-radius: 5rem;
			background-color: map-get($map: $purpleMap, $key: "700");
			color: #ffffff;
			font-size: 1.6rem;
			font-weight: 600;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "medium");
			}
		}
	}
}

.SuccessUI {
	@include fill_container;
	@include flex_col(center, center);
	text-align: center;

	&_success {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
		margin-bottom: 0.5rem;
	}
	&_newAccountID {
		width: 100%;
		font-size: 1.4rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $purpleMap, $key: "700");
		@include flex_row(center, center);

		& > b {
			font-size: 1.5rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}
.ErrorUI {
	@include fill_container;
	@include flex_col(center, center);
	text-align: center;

	&_failedMsg,
	&_failed {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "500");
		margin-bottom: 0.5rem;
		& > b {
			font-size: 1.5rem;
			font-weight: 600;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_errorMsg {
		width: 100%;
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "500");
		margin-bottom: 0.5rem;
	}
}
