@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Dialog {
	position: fixed;
	max-width: 100%;
	width: 45rem;
	height: 25rem;
	top: 50%;
	left: 50%;
	margin-left: -22.5rem;
	margin-top: -12.5rem;
	border-radius: 1rem;
	background-color: #ffffff;
	@include blurredShadow;
	// box-shadow: map-get($map: $shadows, $key: "focusMode");
	z-index: 100;

	&_top {
		position: relative;
		width: 100%;
		height: 8rem;
		padding: 2rem 2rem;
		@include flex_row(flex-start, flex-start);

		&_iconWrapper {
			width: max-content;
			height: max-content;
			padding: 0.8rem;
			border-radius: 150%;
			background-color: map-get($map: $flatColors, $key: "mainRed");
			box-shadow: map-get($map: $shadows, $key: "subtle");
			@include flex_row(center, flex-start);

			&_icon {
				width: 3rem;
				height: 3rem;
				fill: map-get($map: $colors, $key: "mainRed");
			}
		}
		&_title {
			margin-left: 1rem;
			font-size: 2rem;
			font-weight: 700;
			align-self: center;
			color: map-get($map: $colors, $key: "mainRed");
		}
		&_closeIcon {
			position: absolute;
			top: 1rem;
			right: 1rem;
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $colors, $key: "mainRed");
			z-index: 20;
			&:hover {
				cursor: pointer;
			}
		}
	}
	&_inner {
		width: 100%;
		max-height: 10rem;
		height: 8rem;
		padding-left: 7rem;
		padding-right: 2rem;
		margin-bottom: 2rem;

		&_heading {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_subheading {
			font-size: 1.4rem;
			font-weight: 700;
			// color: map-get($map: $blueGreys, $key: "subheadings");
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_text {
			margin-top: 0.5rem;
			font-size: 1.2rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_bottom {
		width: 100%;
		height: 7rem;
		border-radius: 0 0 1rem 1rem;
		padding: 1rem 2rem;
		@include flex_row(flex-end, flex-end);
		background-color: map-get($map: $blueGreys, $key: "light");
	}
}

@media screen and (max-width: 456px) {
	.Dialog {
		max-width: 98%;
		width: 98%;
		height: 25rem;
		top: inherit;
		bottom: 0.5rem;
		left: 50%;
		margin-left: -49%;
		margin-top: -12.5rem;
	}
}
