@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CurrentBannerEntry {
	width: 100%;
	height: auto;
	padding: 0.5rem 1rem;
	border: 2px solid map-get($map: $redMap, $key: "600");
	border-radius: 0.5rem;
	margin: 1rem 0;
	background-color: #ffffff;
	&:hover {
		cursor: pointer;
		box-shadow: 10px 10px 40px 40px #0000ff4d;
		transition: all 0.2s ease-in-out;
		transform: scale(1.01, 1.01);
	}
	&:focus-visible {
		cursor: pointer;
		box-shadow: 10px 10px 40px 40px #0000ff4d;
	}
	&:active {
		cursor: pointer;
		box-shadow: 10px 10px 40px 40px #0000ff4d;
	}
	&:target {
		cursor: pointer;
		box-shadow: 10px 10px 40px 40px #0000ff4d;
	}

	&_top {
		width: 100%;
		margin-bottom: 1rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 800;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}

	&_details {
		width: auto;
		height: auto;
		&_item {
			font-size: 1.5rem;
			font-weight: 500;

			& > b {
				font-weight: 700;
			}
		}

		&_confirmed {
			margin-top: 0.7rem;
			font-size: 1.5rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");

			& > b {
				padding: 0.3rem 1.2rem;
				border-radius: 5rem;
				// border: 1px solid #ccc;
				font-weight: 700;
			}
		}
	}
	&_actions {
		width: auto;
		@include flex_row(flex-end, center);

		&_edit {
			padding: 0.3rem 0.5rem;
			border-radius: 0.5rem;
			font-size: 1.6rem;
			font-weight: 600;
			background-color: map-get($map: $blueMap, $key: "600");
			color: #ffffff;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
