@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UIController {
	@include fill_container;

	&_actions {
		width: 100%;
		margin-top: 2rem;
		margin-bottom: 6rem;
	}
}
