@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityAssociationView {
	@include fill_container;
	@include flex_col(center, flex-start);

	&_actionSelector {
		width: 100%;
		height: auto;
		margin-bottom: 4rem;

		& label {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			padding-left: 0.3rem;
		}
	}

	&_type {
		width: 100%;
		height: 10rem;
		margin: 2rem 0;
		margin-bottom: 4rem;
		@include flex_row(center, center);
	}

	&_main {
		@include fill_container;
		@include flex_row(flex-start, flex-start);

		&_options {
			width: 100%;
			height: auto;

			&_facility {
				width: 100%;
			}
			&_users {
				width: 100%;
				margin-top: 4rem;
			}
		}
	}
}

.ActionIndicator {
	width: fit-content;
	height: fit-content;
	padding: 1rem 2rem;
	border-radius: 5rem;
	@include flex_row(center, center);
	background-color: transparent;
	border: 3px solid map-get($map: $purpleMap, $key: "700");

	&_icon {
		width: 4rem;
		height: 4rem;
		fill: map-get($map: $purpleMap, $key: "700");
		margin-right: 0.5rem;
	}
	&_msg {
		font-size: 2.2rem;
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}
}
