@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ToggleSSO {
	@include fill_container;

	&_header {
		width: 100%;
		height: 10rem;
		margin-bottom: 4rem;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "600");
		}
		&_altDesc,
		&_desc {
			margin-top: 1.5rem;
			font-size: 1.6rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
		}

		&_altDesc {
			text-decoration: underline;
		}
	}
	&_main {
		@include fill_container;
		padding: 0 2rem;
	}
}
