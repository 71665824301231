@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentTarget {
	@include fill_container;
	@include flex_col(space-evenly, flex-start, wrap);

	&_date,
	&_item {
		width: max-content;
		height: auto;
		margin-bottom: 2rem;
	}
	&_date {
		width: auto;
	}
}

.WarningMessage {
	width: 100%;
	height: auto;
	margin: 2rem 0;
	font-size: 1.6rem;
	font-weight: 500;
	font-family: "Open Sans", "Roboto", "Arial", sans-serif;
	color: map-get($map: $redMap, $key: "600");
	& b {
		color: map-get($map: $purpleMap, $key: "700");
	}

	&_warning {
		width: 100%;
		word-wrap: break-word;
		text-overflow: clip;
	}
}
