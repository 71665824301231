@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ResidentSummary {
	width: 100%;
	max-width: 55rem;
	height: auto;

	&_info {
		width: 100%;
		height: 100%;
		padding: 1rem;
		@include flex_col(flex-start, flex-start);
		border-radius: 0.5rem;
		border: 1px solid map-get($map: $blueGreyMap, $key: "300");

		&_item {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin-bottom: 1rem;

			& > b {
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
		}
	}
}
.ResidentSummaryLoader {
	width: auto;
	padding: 2rem;
}
.ResidentNotFound {
	width: 100%;
	height: auto;
	font-size: 2rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "600");
}
