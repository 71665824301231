@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateNewVendorRowForm {
	width: 100%;
	height: auto;
	padding: 2rem 2rem;

	&_wrapper {
		width: auto;

		&_label {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}

		&_form {
			border: 1px solid #ccc;
			border-radius: 0.5rem;
			@include fill_container;
			padding: 2rem 2rem;
			display: grid;
			grid-template-columns: 50% 50%;
			gap: 2rem 0;

			&_field {
				width: auto;
				margin: 0.5rem 0.5rem;
			}
		}
		&_actions {
			width: 100%;
			@include flex_row(flex-end, center);
			margin-top: 2rem;
		}
	}
}
