@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserTypesController {
	@include fill_container;

	&_admin {
		width: 100%;
		height: auto;
		border-bottom: 1px solid #ccc;
		padding: 1rem 0;
		margin-bottom: 2rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
		}

		&_selector {
			margin-bottom: 4rem;
		}
	}

	&_option {
		margin: 1rem 0;
	}

	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
	}
}
