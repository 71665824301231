@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CreateAdmin {
	width: max-content;
	height: 100%;

	&_top {
		width: 100%;
		height: auto;
		padding: 0 2rem;
		margin-bottom: 1rem;

		&_title {
			font-size: 1.8rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
	}
	&_form {
		@include fill_container;
		padding: 1rem 2rem;

		&_entry {
			width: 100%;
			height: auto;
			margin-bottom: 2rem;
		}
		&_actions {
			@include flex_row(flex-end, center);
			width: 100%;
			height: auto;
			margin: 2rem 0;
		}
	}
}
