@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ExportFacilityData {
	width: auto;
	padding: 4rem 2rem;
	margin-bottom: 5rem;

	&_selector {
		width: 100%;
		margin-bottom: 4rem;

		&_label {
			font-size: 1.5rem;
			font-weight: 400;
			color: map-get($map: $blueGreyMap, $key: "400");
		}
	}
}
