@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AdminPage {
	@include fill_container;
	padding: 2rem;

	&_header {
		width: 100%;
		height: auto;

		&_title {
			font-size: 3rem;
			color: map-get($map: $blueGreyMap, $key: "800");

			& > b {
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}
	&_selector {
		width: 100%;
		height: 10rem;
		margin-bottom: 4rem;
		@include flex_row(center, center);
	}
}

.IconButton {
	position: relative;
	@include fill_container;
	@include flex_row(center, center);
	z-index: -1;

	&_icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: map-get($map: $blueGreyMap, $key: "500");
		margin-right: 0.5rem;
	}
	&_name {
		font-size: 2rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
	&_isActive {
		@extend .IconButton;

		&_icon {
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $purpleMap, $key: "500");
			margin-right: 0.5rem;
		}
		&_name {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "500");
		}
	}
	&_isDisabled {
		@extend .IconButton;
		opacity: 0.5;
		cursor: not-allowed;
	}
}
