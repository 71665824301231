@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityActiveUsersReport {
	@include fill_container;

	&_details {
		width: 100%;
		height: auto;

		&_desc {
			font-size: 1.5rem;
			margin-bottom: 4rem;
		}
	}
	&_report {
		width: 100%;
		@include flex_col(center, flex-start);

		&_summary {
			width: 100%;
			margin-bottom: 3rem;

			&_count {
				font-size: 1.7rem;
				color: map-get($map: $blueGreyMap, $key: "500");

				& > b {
					font-size: 1.8rem;
					font-weight: 800;
					font-family: map-get($map: $fonts, $key: "altSans");
					color: map-get($map: $blueMap, $key: "600");
				}
			}
		}
		&_search {
			width: 35rem;
			height: 3.5rem;
			align-self: center;
			border-radius: 5rem;
			margin-bottom: 2rem;

			&_input {
				@include fill_container;
				border-radius: 5rem;
				border: none;
				outline: none;
				background-color: #eaecef;
				padding-left: 0.7rem;
				font-size: 1.6rem;

				&:hover {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
					cursor: pointer;
				}
				&:focus-within {
					box-shadow: map-get($map: $shadows, $key: "mainOutline");
					cursor: pointer;
				}
				&::placeholder {
					color: map-get($map: $blueGreyMap, $key: "400");
				}
			}
		}
		&_selector {
			width: 100%;
			height: auto;
			@include flex_row(center, center);
			margin-bottom: 4rem;
		}
		&_entries {
			width: 100%;
			min-height: fit-content;
			max-height: 60rem;
			overflow-y: auto;
			border-top: 10px solid map-get($map: $blueGreyMap, $key: "200");
			border-bottom: 10px solid map-get($map: $blueGreyMap, $key: "200");
			border-left: 2px solid map-get($map: $blueGreyMap, $key: "200");
			border-right: 2px solid map-get($map: $blueGreyMap, $key: "200");
			border-radius: 0.3rem 0.3rem;

			/* Background tracking */
			// &::-webkit-scrollbar-track {
			// 	background-color: map-get($map: $blueGreyMap, $key: "100");
			// 	border-radius: 5rem;
			// }
			// /* Sets width/height of 'thumb' & hides default */
			// &::-webkit-scrollbar {
			// 	appearance: none;
			// 	-webkit-appearance: none;
			// 	width: 1rem;
			// }
			// &::-webkit-scrollbar-thumb {
			// 	background-color: map-get($map: $blueGreyMap, $key: "500");
			// 	border-radius: 5rem;
			// }

			&_actions {
				width: 100%;
				height: 5rem;
				@include flex_row(flex-end, flex-start);
				background-color: map-get($map: $blueGreyMap, $key: "200");
			}
			&_users {
				min-height: fit-content;
				max-height: 60rem;
				overflow-y: auto;
				border-top: 10px solid map-get($map: $blueGreyMap, $key: "200");
				border-bottom: 10px solid map-get($map: $blueGreyMap, $key: "200");
				border-left: 2px solid map-get($map: $blueGreyMap, $key: "200");
				border-right: 2px solid map-get($map: $blueGreyMap, $key: "200");
				border-radius: 0.3rem 0.3rem;
				/* Background tracking */
				&::-webkit-scrollbar-track {
					background-color: map-get($map: $blueGreyMap, $key: "100");
					border-radius: 5rem;
				}
				/* Sets width/height of 'thumb' & hides default */
				&::-webkit-scrollbar {
					appearance: none;
					-webkit-appearance: none;
					width: 1rem;
				}
				&::-webkit-scrollbar-thumb {
					background-color: map-get($map: $blueGreyMap, $key: "500");
					border-radius: 5rem;
				}
			}
		}
		&_main {
			width: 100%;

			&_headings {
				width: 100%;
				height: 4rem;
				@include flex_row(space-between, center);
				background-color: map-get($map: $blueGreyMap, $key: "800");
				padding: 0 0.3rem;

				&_name {
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "100");
					width: 27rem;
					max-width: 27rem;
				}
				&_email {
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "100");
					width: 25rem;
					max-width: 25rem;
					@include flex_row(flex-start, center);
					justify-self: flex-start;
					// margin-right: auto;
				}
				&_community {
					width: 25rem;
					max-width: 25rem;
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "100");
					// @include flex_row(flex-end, center);
					@include flex_row(flex-start, center);
				}
				&_login {
					width: 12rem;
					max-width: 12rem;
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "100");
					@include flex_row(flex-start, center);
				}
				&_count {
					width: 12rem;
					max-width: 12rem;
					font-size: 1.6rem;
					font-weight: 700;
					color: map-get($map: $blueGreyMap, $key: "100");
					@include flex_row(flex-end, center);
				}
			}
		}
	}
}
