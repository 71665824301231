@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserReportEntry {
	width: 100%;
	height: auto;
	padding: 0.5rem 0.3rem;
	@include flex_row(space-between, center);

	&:hover {
		cursor: pointer;
	}
	&:hover &_name {
		color: map-get($map: $blueMap, $key: "600");
	}
	&:hover &_email {
		color: map-get($map: $blueMap, $key: "600");
	}
	&:hover &_community {
		color: map-get($map: $blueMap, $key: "600");
	}

	&:nth-child(even) {
		background-color: #eaecef;
	}

	&_name {
		font-size: 1.6rem;
		width: 27rem;
		max-width: 27rem;
	}
	&_email {
		font-size: 1.6rem;
		width: 25rem;
		max-width: 25rem;
		@include flex_row(flex-start, center);
		justify-self: flex-start;
	}
	&_community {
		width: 25rem;
		max-width: 25rem;
		font-size: 1.6rem;
		@include flex_row(flex-start, center);
	}
	&_login {
		width: 12rem;
		max-width: 12rem;
		font-size: 1.4rem;
		@include flex_row(flex-start, center);
	}
	&_count {
		width: 10rem;
		max-width: 10rem;
		font-size: 1.6rem;
		@include flex_row(center, center);
	}
}
