@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LineChartWrapper {
	@include fill_container;
	border-radius: 0.5rem;

	&_header {
		width: 100%;
		height: auto;
		min-height: 6rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 600;
			color: map-get($map: $purpleMap, $key: "700");
		}
		&_subtitle {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_chart {
		width: 100%;
		height: auto;
		min-height: 50rem;
		border-radius: 0.5rem;
	}
}
