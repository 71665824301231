@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.RenameCommunityView {
	@include fill_container;

	&_selectedName {
		width: max-content;
		margin-top: 4rem;
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 2rem;
	}
	&_main {
		@include fill_container;
		@include flex_row(space-between, flex-start, wrap);
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
