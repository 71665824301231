@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DisassociateFacilityFromUsers {
	width: 100%;

	&_desc {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
		margin-bottom: 4rem;
	}
	&_warning {
		font-size: 1.6rem;
		font-weight: 800;
		color: map-get($map: $redMap, $key: "600");
		margin: 4rem 0;
	}
	&_summary {
		font-size: 1.5rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "600");
		margin-bottom: 2rem;
		margin-top: 1rem;

		& > b {
			font-size: 2rem;
			font-weight: 800;
			color: map-get($map: $blueMap, $key: "600");
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		padding: 2rem 0;
	}
}
