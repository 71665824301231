@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UnSuspendController {
	@include fill_container;

	&_summary {
		margin-bottom: 2rem;
	}

	&_search {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		margin: 4rem 0;

		&_input {
			width: 70rem;
			height: 4rem;
			border: none;
			outline: none;
			padding-left: 1.5rem;
			background-color: #ffffff;
			font-size: 1.7rem;
			border-radius: 5rem;

			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
		}
	}

	&_actions {
		width: 100%;
		padding: 2rem;

		&_btn {
			padding: 1.5rem 2.2rem;
			border-radius: 5rem;
			background-color: map-get($map: $redMap, $key: "600");
			color: #ffffff;
			font-size: 1.7rem;
			font-weight: 600;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "medium");
			}
			&:disabled {
				cursor: not-allowed;
				opacity: 0.4;
			}
		}
	}
}

.SuspendedList {
	width: auto;
	height: auto;
	padding: 0 4rem;

	&_list {
		list-style: circle;
		@include flex_col(flex-start, flex-start, wrap);

		&_entry {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "700");
			margin: 2rem 0;

			&_name {
				font-size: 1.7rem;
				font-size: 1.7rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "700");
			}
			&_date {
				font-size: 1.4rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "500");
			}
		}
	}
}

.SuspendedEntry {
	font-size: 1.7rem;
	font-weight: 600;
	color: map-get($map: $blueGreyMap, $key: "600");
	margin: 2rem 0;
	&:hover {
		cursor: pointer;
		color: map-get($map: $purpleMap, $key: "600");
	}

	&_name {
		width: auto;
	}
	&_date {
		font-size: 1.4rem;
		font-weight: 500;
		font-family: "Open Sans", "Arial", sans-serif;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
}

.UnSuspendSummary {
	width: 100%;
	height: auto;

	&_text {
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");

		& > b {
			font-size: 1.6rem;
			font-weight: 600;
			color: map-get($map: $blueMap, $key: "600");
		}
	}
}
