@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsTable {
	width: 100%;
	height: auto;
	min-height: 50rem;
	border: 2px solid #000;
	border-top: none;
	border-radius: 1rem 1rem 0.5rem 0.5rem;
	margin-top: 6rem;
	// margin-bottom: 4rem;

	&_columns {
		width: 100%;
		height: 6rem;
		border-radius: 1rem 1rem 0 0;
	}
	&_rows {
		width: 100%;
		height: 50rem;
		overflow-y: auto;
		overflow-x: hidden;
		/* Background tracking */
		&::-webkit-scroll-track {
			background-color: #eaecef;
		}
		/* Sets width/height of 'thumb' & hides default */
		&::-webkit-scrollbar {
			appearance: none;
			-webkit-appearance: none;
			width: 0.8rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: map-get($map: $blueGreyMap, $key: "400");
			border-radius: 5rem;
		}
	}
}

.NoRecordsFound {
	width: 100%;
	height: auto;
	padding: 4rem 2rem;
	@include flex_row(center, center);

	&_msg {
		text-align: center;
		font-size: 1.8rem;
		font-weight: 700;
		color: map-get($map: $orangeMap, $key: "600");
	}
}
