@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ChangeUserType {
	@include fill_container;
	padding-bottom: 10rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;

		&_title {
			font-size: 2rem;

			& > b {
				color: map-get($map: $redMap, $key: "600");
			}
		}
		&_subtitle {
			font-size: 1.5rem;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}

	&_selector {
		width: 100%;
		@include flex_row(center, flex-start);
		padding: 4rem 2rem;
	}
	&_userType {
		width: 100%;
	}

	&_summary {
		width: 100%;
		@include flex_row(center, flex-start);
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "600");
		margin-bottom: 4rem;
		font-family: "Open Sans", Arial, Helvetica, sans-serif;

		& span > b {
			color: map-get($map: $redMap, $key: "600");
		}
	}

	&_otherTypes {
		width: 100%;
		border-top: 1px solid #ccc;
		margin: 4rem 0;
	}

	&_actions {
		width: 100%;
		@include flex_row(center, flex-start);
		padding: 1rem 2rem;
	}
}
