@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilityAdmin {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 4rem;

		&_title {
			font-size: 2rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "500");
			text-align: center;
			& > b {
				font-weight: 400;
				text-decoration: underline;
			}
		}
	}

	&_choices {
		width: 100%;
		@include flex_row(space-evenly, center);
	}

	&_options {
		width: 100%;
		margin-top: 4rem;
		margin-bottom: 4rem;
		@include flex_row(center, flex-start);

		&_option {
			@include fill_container;
		}
	}
}

.AdminOption {
	padding: 1.3rem 2.5rem;
	border-radius: 1rem;
	background-color: map-get($map: $purpleMap, $key: "700");
	color: #ffffff;
	font-size: 1.7rem;
	font-weight: 600;
	border: none;
	outline: none;
	box-shadow: map-get($map: $shadows, $key: "medium");
	cursor: pointer;

	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
	}
}
