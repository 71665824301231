@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginsByDateViz {
	@include fill_container;
	color: map-get($map: $blueGreyMap, $key: "100");

	&_viz {
		@include fill_container;
		max-width: 140rem;
		height: 75rem;
		padding: 1rem;
		background-color: map-get($map: $blueGreyMap, $key: "900");
		border-radius: 1rem;
	}
}
