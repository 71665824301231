@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MoveToParentFacility {
	@include fill_container;
	padding: 0 2rem;

	&_section {
		width: 100%;
		@include flex_col(flex-end, center);
		& label {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_actions {
		margin-top: 2rem;
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
