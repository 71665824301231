@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/animations";
@import "../../sass/custom";

.ModalLG {
	position: fixed;
	top: 50%;
	left: 50%;
	max-width: 90%;
	max-height: 90%;
	width: 90vw;
	height: 90vh;
	margin-left: -45vw;
	margin-top: -45vh;
	background-color: #ffffff;
	border-radius: 1rem;
	@include slide_in_center;
	box-shadow: map-get($map: $shadows, $key: "focusMode");
	z-index: 20;

	&_top {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;
		@include flex_row(space-between, center);

		&_title {
			font-size: 2rem;
			font-weight: 600;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&_icon {
			width: 2.2rem;
			height: 2.2rem;
			fill: map-get($map: $colors, $key: "mainRed");
			cursor: pointer;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:hover {
				background-color: map-get($map: $greys, $key: "chalk");
				border-radius: 0.5rem;
				-webkit-transform: rotateZ(360deg);
				transform: rotateZ(360deg);
			}
		}
	}
	&_main {
		@include fill_container;
		max-height: 95%;
		padding: 2rem 2rem;
		padding-bottom: 4rem;
		overflow-y: scroll;
	}
}

@media only screen and (max-width: 700px) {
	.ModalLG {
		position: fixed;
		top: 50%;
		left: 50%;
		max-width: 90%;
		max-height: 80%;
		width: 90vw;
		height: 80vh;
		margin-left: -45vw;
		margin-top: -40vh;
		background-color: #ffffff;
		border-radius: 1rem;
		box-shadow: map-get($map: $shadows, $key: "focusMode");
		z-index: 20;

		&_top {
			width: 100%;
			height: auto;
			padding: 1rem 2rem;
			@include flex_row(space-between, center);

			&_title {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $blueGreys, $key: "subheadings");
			}
		}
	}
}
