@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MakeUserAdmin {
	@include fill_container;

	&_options {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}
	&_actions {
		width: 100%;
		height: auto;
		margin-bottom: 1rem;
		@include flex_row(flex-end, center);
	}
}
