@import "../../sass/mixins";
@import "../../sass/variables";
@import "../../sass/custom";

.PasswordInput {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;

	&_label {
		font-size: 1.6rem;
		font-weight: 400;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_wrapper {
		position: relative;
		@include flex_row(flex-start, center);
		padding: 0;
		border-radius: 0.5rem;
		background-color: map-get($map: $colors, $key: "mainGrey");

		&_input {
			width: 100%;
			height: 3.5rem;
			border: none;
			outline: none;
			border-radius: 0.5rem;
			background-color: map-get($map: $colors, $key: "mainGrey");
			padding-left: 0.5rem;
			font-size: 1.6rem;
			font-weight: 500;
			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}

			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "400");
				opacity: 0.6;
			}
			&:disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
		}
		&_icon {
			position: absolute;
			top: 1rem;
			right: 1rem;
			width: 1.6rem;
			height: 1.6rem;
			fill: map-get($map: $blueGreys, $key: "subheadings");
			z-index: 10;
			&:hover {
				cursor: pointer;
				fill: map-get($map: $blueGreys, $key: "headings");
			}
		}
		&_capslock {
			position: absolute;
			bottom: -1.7rem;
			right: 0;
			color: map-get($map: $redMap, $key: "500");
			font-size: 1.3rem;
			font-weight: 500;
		}
	}
}

.Password:disabled .PasswordInput_wrapper {
	opacity: 0.4;
	cursor: not-allowed;
}
