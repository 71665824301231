@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FlushCache {
	width: 100%;
	height: auto;
	min-height: 60rem;
	@include flex_row(flex-start, center);

	&_options {
		width: auto;
		height: auto;
		margin-right: 1rem;
		align-self: baseline;
	}

	&_actions {
		width: auto;
		height: auto;
		margin-top: 2rem;
		align-self: flex-start;
	}
}
