@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ServiceBanner {
	width: 100%;
	height: auto;
	padding: 0.5rem 1rem;
	border: 1px solid map-get($map: $redMap, $key: "300");
	border-radius: 0.5rem;
	margin: 1rem 0;

	&_community {
		width: auto;
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "600");

		& > b {
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_message {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $blueGreyMap, $key: "700");
	}
	&_expiry {
		width: 100%;
		height: auto;
		margin: 2rem 0;
		font-size: 1.8rem;
		font-weight: 600;
		font-family: map-get($map: $fonts, $key: "altSans");
		color: map-get($map: $blueGreyMap, $key: "700");
	}
}
