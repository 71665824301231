@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAdminView {
	@include fill_container;
	padding-bottom: 4rem;

	&_header {
		width: 100%;
		height: auto;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_desc {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_subdesc {
			margin-top: 2rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "500");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		padding: 4rem 2rem;

		&_facility {
			width: 100%;
			height: auto;
			margin-bottom: 4rem;

			&_label {
				font-size: 1.5rem;
				font-weight: 500;
				color: map-get($map: $blueGreyMap, $key: "600");
			}
			&_name {
				font-size: 1.6rem;
				font-weight: 600;
				color: map-get($map: $redMap, $key: "600");
			}
		}
	}
}

.SaveAsAdminDialog {
	width: 100%;
	height: 100%;
	@include flex_row(flex-end, center);
}
.NewAdminDialog {
	width: 100%;
	height: 100%;
	@include flex_row(flex-end, center);
}

.Subheading {
	width: inherit;
	height: auto;

	& > b {
		color: map-get($map: $redMap, $key: "600");
		font-weight: 700;
		text-decoration: underline;
	}
}
