@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ReasonForSuspension {
	@include fill_container;

	&_main {
		width: 45rem;
		height: auto;
		min-height: 15rem;
		padding: 1rem;
		border-radius: 0.5rem;
		border: 1px solid map-get($map: $blueGreyMap, $key: "400");

		&_selection {
			width: max-content;
			margin: 2rem 0;
		}
	}
}
