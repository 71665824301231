@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MoveIndependentToParent {
	@include fill_container;

	&_section {
		width: 100%;
		height: auto;
		padding: 1rem 2rem;

		& label {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_actions {
		width: 100%;
		height: auto;
		@include flex_row(flex-end, center);
	}
}
