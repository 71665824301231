@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DisassociateView {
	width: 100%;
	height: auto;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
		&_desc {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
	&_main {
		width: 100%;
		height: auto;

		&_section {
			@include fill_container;
			padding: 2rem;

			&_subtitle {
				font-size: 2rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");
			}
		}
	}
}
