@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.StaxControllerView {
	@include fill_container;

	&_header {
		width: 100%;
		margin-bottom: 6rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}

		&_desc {
			font-size: 1.8rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
	&_main {
		width: 100%;
		height: auto;
		position: relative;
	}
}
