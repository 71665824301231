@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginsByDate {
	width: 100%;
	padding: 2rem;

	&_title {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "800");
		font-family: map-get($map: $fonts, $key: "altSans");
		& > b {
			color: map-get($map: $purpleMap, $key: "600");
		}
	}
}

.DateEntry {
	width: fit-content;
	height: auto;
	font-size: 1.6rem;
	font-weight: 500;
	font-family: map-get($map: $fonts, $key: "altSans");
	// @include flex_row(flex-start, center);

	&_date {
		font-weight: 600;
		color: map-get($map: $purpleMap, $key: "700");
	}
	&_about {
		font-weight: 500;
	}
}
