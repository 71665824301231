@import "../../sass/variables";
@import "../../sass/mixins";

.DatePickerSM {
	position: relative;
	width: 20rem;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	border-radius: 0.5rem;

	&_label {
		font-size: 1.4rem;
		font-weight: 600;
		color: map-get($map: $blueGreys, $key: "subheadings");
	}
	&_input {
		width: 100%;
		height: 3.5rem;
		padding-left: 0.5rem;
		background-color: #ffffff;
		border-radius: 0.5rem;
		border: 1px solid #ccc;
		outline: none;
		font-size: 1.6rem;
		&:hover,
		&:focus {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
		&::-webkit-input-placeholder {
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&::-moz-placeholder {
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&:-ms-input-placeholder {
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&::-ms-input-placeholder {
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
		&::placeholder {
			font-weight: 300;
			color: map-get($map: $blueGreys, $key: "subheadings");
		}
	}
	&_icon {
		position: absolute;
		bottom: 1rem; // 1rem
		right: 1rem; // 2.6rem
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $blueGreys, $key: "subheadings");
		&:hover {
			cursor: pointer;
			fill: map-get($map: $blueGreys, $key: "headings");
		}
	}
}
