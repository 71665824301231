@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FullPageTabButtons {
	width: 100%;
	height: auto;
	padding: 1rem;
	padding-bottom: 0;
	border-bottom: none;
	border-radius: 1rem 1rem 0 0;
	margin-bottom: 4rem;
	background-color: transparent;
	@include flex_row(center, center, wrap);
}

@media only screen and (max-width: 650px) {
	.FullPageTabButtons {
		@include flex_row(center, center, wrap);
	}
}
