@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.SuspendStatus {
	width: 100%;
	height: auto;

	&_status {
		width: auto;
		color: map-get($map: $blueGreyMap, $key: "600");
		font-size: 1.6rem;
		font-weight: 500;

		& > b {
			color: map-get($map: $redMap, $key: "600");
		}
	}
}
