@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.LoginsByUser {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;

	&_title {
		font-size: 2rem;
		font-weight: 700;
		color: map-get($map: $blueGreyMap, $key: "800");
		font-family: map-get($map: $fonts, $key: "altSans");
		& > b {
			color: map-get($map: $purpleMap, $key: "600");
		}
	}

	&_list {
		padding: 1rem 2rem;
		list-style: decimal;
		color: map-get($map: $blueGreyMap, $key: "500");
		font-family: map-get($map: $fonts, $key: "altSans");
	}
}

.LoginEntry {
	font-size: 1.6rem;
	font-weight: 600;

	& > b {
		color: map-get($map: $purpleMap, $key: "600");
	}
}
