@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsTableForm {
	width: auto;
	height: auto;

	&_label {
		width: 100%;
		font-size: 1.6rem;
		font-weight: 700;
		color: map-get($map: $blueMap, $key: "600");
		padding-left: 0.5rem;
	}

	&_form {
		border: 1px solid #ccc;
		border-radius: 0.5rem;
		@include fill_container;
		padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 2rem 0;
		// @include flex_row(center, center, wrap);
		// gap: 3rem 5rem;

		&_field {
			width: auto;
			margin: 0.5rem 0.5rem;
		}
	}
	&_actions {
		width: 100%;
		@include flex_row(flex-end, center);
		margin-top: 2rem;
	}
}
