@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ImportDataExample {
	// width: auto;
	width: 100%;

	&_text {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 600;
		color: map-get($map: $blueMap, $key: "600");
		margin-bottom: 2rem;
	}
	&_card {
		width: 100%;
		@include flex_row(center, flex-start);
	}
	&_link {
		width: 100%;
		height: auto;
		@include flex_col(center, center);
		margin-bottom: 6rem;

		&_or {
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
	}
}

.CustomLink {
	width: auto;
	height: auto;
	margin-top: 2rem;
	font-size: 1.6rem;
	font-weight: 600;
	color: map-get($map: $blueMap, $key: "600");
}
