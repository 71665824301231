@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MakeRegionalAdmin {
	width: 100%;
	height: auto;
	min-height: 50rem;

	&_header {
		width: 100%;
		height: auto;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_desc {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_subdesc {
			margin-top: 2rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "500");
		}
	}

	&_notice {
		width: 100%;
		margin: 3rem 0;
		font-size: 1.6rem;
		color: map-get($map: $purpleMap, $key: "700");
	}

	&_main {
		width: 100%;
		height: auto;

		&_selector {
			width: 100%;
			margin: 4rem 0;
		}
		&_actions {
			width: 100%;
			height: auto;
			@include flex_row(flex-start, center);
		}
	}
}
