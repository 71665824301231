@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AccountView {
	@include fill_container;
	// background-color: map-get($map: $blueGreyMap, $key: "800");

	&_header {
		width: auto;
		height: auto;

		&_main {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "700");
			margin-bottom: 2rem;
		}
		&_desc {
			width: 50rem;
			max-width: 100%;
			font-size: 1.6rem;
			font-weight: 500;
			font-family: "Open Sans", "Roboto", "Arial", sans-serif;
			color: map-get($map: $blueGreyMap, $key: "600");
			margin-bottom: 4rem;
		}

		&_title {
			width: max-content;
			font-size: 1.8rem;
			font-weight: 700;
			padding-left: 1.5rem;
			color: map-get($map: $blueGreyMap, $key: "700");
		}
		&_subtitle {
			@include flex_row(flex-start, center);
			width: max-content;
			font-size: 1.4rem;
			font-weight: 500;
			padding-left: 1.5rem;
			font-family: "Open Sans", "Roboto", "Arial", sans-serif;
			color: map-get($map: $blueGreyMap, $key: "400");

			& > b {
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "500");
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
	&_main {
		width: 100%;
		height: auto;
		position: relative;
	}
}
