@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.AccordionSection {
	width: 100%;
	height: auto;
	background-color: #ffffff;
	border-radius: 0.5rem;
	border-bottom: 1px solid #eaecef;

	&_top {
		width: 100%;
		height: 4rem;

		&_btn {
			@include fill_container;
			@include flex_row(space-between, center);
			padding: 1rem 2rem;
			outline: none;
			border: none;
			background-color: transparent;
			&:hover {
				cursor: pointer;
			}
			&:hover &_icon {
				cursor: pointer;
				fill: map-get($map: $colors, $key: "main");
			}
			&:hover span {
				color: map-get($map: $colors, $key: "main");
			}
			& span {
				font-size: 1.5rem;
				font-weight: 600;
				color: map-get($map: $colors, $key: "mainBlackBlue");
			}

			&_icon {
				width: 1.8rem;
				height: 1.8rem;
				fill: map-get($map: $colors, $key: "mainBlackBlue");
				transition: all 0.2s ease-in-out;
				&:hover {
					cursor: pointer;
					fill: map-get($map: $colors, $key: "main");
				}
			}
		}
	}
	&_content {
		@include fill_container;
		padding: 2rem;
	}
}
