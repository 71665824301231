@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsController {
	width: 100%;
	height: auto;

	&_selector {
		width: auto;
		margin-bottom: 6rem;
	}

	&_table {
		width: auto;

		&_top {
			width: 100%;
			height: 5rem;
			@include flex_row(flex-start, flex-end);
			// @include flex_row(space-evenly, flex-end);
			margin-bottom: -5rem;
		}

		&_rowCount {
			width: 50%;
			@include flex_row(flex-end, center);
			font-size: 1.6rem;
			font-family: map-get($map: $fonts, $key: "altSans");
			color: map-get($map: $blueGreyMap, $key: "500");
			// margin-bottom: -4rem;

			& > b {
				font-size: 2rem;
				margin: 0 0.5rem;
				color: map-get($map: $blueMap, $key: "600");
			}
		}
	}
}

.VendorTableSearch {
	width: 40rem;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	@include flex_row(center, flex-end);

	&_input {
		width: 100%;
		height: 3.5rem;
		border-radius: 5rem;
		font-size: 1.6rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "700");
		padding-left: 1rem;
		border: none;
		outline: none;

		&::placeholder {
			color: map-get($map: $blueGreyMap, $key: "300");
		}

		&:hover {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
		&:focus-within {
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
			cursor: pointer;
		}
	}
}

.RowCount {
	width: 50%;
	@include flex_row(flex-end, center);
	font-size: 1.6rem;
	font-family: map-get($map: $fonts, $key: "altSans");
	color: map-get($map: $blueGreyMap, $key: "500");
	// margin-bottom: -4rem;
	margin-left: auto;

	& > b {
		font-size: 2rem;
		margin: 0 0.5rem;
		color: map-get($map: $blueMap, $key: "600");
	}
}
