@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.TransferResident {
	width: 100%;
	height: auto;
	@include flex_row(flex-start, flex-start, wrap);
	flex-grow: 1;

	&_left {
		width: 50%;
		height: auto;
		flex-shrink: 2;

		&_search {
			width: max-content;
			@include flex_row(flex-start, center);
			margin-bottom: 2rem;
		}
		&_summary {
			width: 100%;
		}
	}
	&_right {
		width: 50%;
		height: auto;
		flex-shrink: 1;
	}
}

.WarningMessage {
	width: 100%;
	height: auto;
	margin: 2rem 0;
	font-size: 1.6rem;
	font-weight: 600;
	color: map-get($map: $redMap, $key: "500");
	& > b {
		color: map-get($map: $purpleMap, $key: "700");
	}
}
