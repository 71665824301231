@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.PhoneInput {
	width: 100%;
	max-width: 82rem;
	border-radius: 0.5rem;

	&_label {
		font-size: 1.5rem;
		font-weight: 500;
		color: map-get($map: $blueGreyMap, $key: "500");
	}
	&_wrapper {
		width: 100%;
		height: auto;
		position: relative;

		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			padding-left: 0.5rem;
			font-size: 1.6rem;
			font-weight: 500;
			background-color: #eaecef;
			&:hover,
			&:focus-within {
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
				cursor: pointer;
			}
			&::placeholder {
				color: map-get($map: $blueGreyMap, $key: "300");
			}
		}
		&_close {
			position: absolute;
			top: 0.5rem;
			right: 2rem;
			// transform: translateY(-50%);
			@include flex_row(center, center);
			width: 2.2rem;
			height: 2.2rem;
			border-radius: 50%;
			background-color: map-get($map: $blueGreyMap, $key: "300");
			&:hover {
				cursor: pointer;
			}

			&_icon {
				width: 1.8rem;
				height: 1.8rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
	}
}
