@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsTableColumns {
	width: 100%;
	height: 6rem;
	background-color: map-get($map: $blueGreyMap, $key: "900");
	border-radius: 1rem 1rem 0 0;

	&_inner {
		@include fill_container;
		@include flex_row(space-evenly, center);
	}
}

.Column {
	width: calc(100% / 6);
	height: 100%;
	@include flex_row(center, center);
	text-align: center;
	font-size: 1.8rem;
	font-weight: 700;
	color: map-get($map: $blueGreyMap, $key: "200");
}
