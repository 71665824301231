@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserReportDetails {
	@include fill_container;

	&_about {
		width: 100%;
		padding: 1rem 2rem;

		&_item {
			width: auto;
			@include flex_row(flex-start, center);
			font-size: 1.8rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin: 1rem 0;

			& > b {
				color: map-get($map: $blueGreyMap, $key: "800");
			}
		}
	}
}
