@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.Divider {
	width: 100%;
	display: block;
	margin: 2rem 0;
	opacity: 0.4;
}
