@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FacilitySummary {
	width: min-content;
	height: auto;

	&_main {
		width: 45rem;
		height: auto;
		padding: 1rem;
		min-height: 35rem;
		border-radius: 0.5rem;
		border: 1px solid map-get($map: $blueGreyMap, $key: "400");

		&_item {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
			margin: 1rem 0;

			& > b {
				color: map-get($map: $blueGreyMap, $key: "700");
				font-weight: 600;
			}
		}
	}
}
