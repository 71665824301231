@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorTableActions {
	width: auto;
	margin-right: auto;

	&_new {
		padding: 0.5rem 1.2rem;
		border-radius: 0.5rem;
		font-size: 1.5rem;
		font-weight: 600;
		background-color: map-get($map: $blueMap, $key: "600");
		color: #ffffff;
		border: none;
		outline: none;
		&:hover {
			cursor: pointer;
			box-shadow: map-get($map: $shadows, $key: "mainOutline");
		}
	}
}
