@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityActivityView {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
		&_desc {
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}
		&_subdesc {
			margin-top: 2rem;
			font-size: 1.4rem;
			font-weight: 500;
			color: map-get($map: $redMap, $key: "500");
		}
	}
	&_main {
		@include fill_container;
	}
}
