@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.UserAdmin {
	@include fill_container;
	padding-bottom: 10rem;

	&_options {
		margin-top: 5rem;
		border-top: 1px solid #ccc;
	}
}
