@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.VendorMapsView {
	@include fill_container;
	padding: 2rem;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $purpleMap, $key: "700");
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
}
