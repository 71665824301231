@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EmailValidator {
	width: 100%;
	height: auto;

	&_inputWrapper {
		width: 100%;
		height: auto;
		// max-width: 50rem;
		border-radius: 0.5rem;

		&_label {
			font-size: 1.5rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "500");
		}

		&_input {
			width: 100%;
			height: 3.5rem;
			border-radius: 0.5rem;
			border: none;
			outline: none;
			background-color: #ffffff;
			font-size: 1.6rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "700");
			padding-left: 0.5rem;

			&::placeholder {
				font-size: 1.5rem;
				color: map-get($map: $blueGreyMap, $key: "300");
			}
		}
	}
}

.ErrorMsg {
	width: max-content;
	height: auto;
	padding: 0.5rem 1rem;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $redMap, $key: "600");
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $redMap, $key: "600");
		margin-left: 0.3rem;
	}
}

.ValidMsg {
	width: max-content;
	height: auto;
	padding: 0.5rem 1rem;
	@include flex_row(flex-start, center);

	&_icon {
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $greenMap, $key: "500");
	}
	&_msg {
		font-size: 1.4rem;
		font-weight: 500;
		color: map-get($map: $greenMap, $key: "500");
		margin-left: 0.3rem;
	}
}
