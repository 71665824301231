@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CopyText {
	position: relative;
	width: max-content;
	height: max-content;
	padding: 0.5rem 0.8rem;
	font-size: 1.6rem;
	font-weight: 600;
	border-radius: 0.5rem;
	&:hover {
		cursor: pointer;
		background-color: map-get($map: $greys, $key: "chalk");
		color: map-get($map: $colors, $key: "main");
	}

	&_successMsg {
		font-size: 1.2rem;
		font-weight: 500;
		color: map-get($map: $purpleMap, $key: "700");
	}
	&_icon {
		position: absolute;
		top: 0.5rem;
		right: -2rem;
		width: 1.6rem;
		height: 1.6rem;
		fill: map-get($map: $blueGreys, $key: "headings");
	}
}

@media only screen and (max-width: 500px) {
	.CopyText {
		position: relative;
		width: max-content;
		height: max-content;
		padding: 0.5rem 0.8rem;
		font-size: 1.2rem;
		font-weight: 600;
		border-radius: 0.5rem;
		word-wrap: break-word;
		word-break: break-all;
	}
}
