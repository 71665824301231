@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityActivity {
	@include fill_container;

	&_date {
		width: 100%;
		height: auto;
		padding: 4rem 2rem;
		margin-bottom: 2rem;

		&_label {
			margin-top: 2rem;
			font-size: 1.7rem;
			font-family: "Open Sans", Arial, Helvetica, sans-serif;

			& > b {
				color: map-get($map: $purpleMap, $key: "700");
				font-family: "Open Sans", Arial, Helvetica, sans-serif;
			}
		}
	}
}
