@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.BannersController {
	width: 100%;

	&_options {
		width: 100%;
		@include flex_row(space-evenly, center);
		margin: 4rem 1rem;

		&_item {
			padding: 1rem 2rem;
			border-radius: 0.5rem;
			border: 1px solid map-get($map: $purpleMap, $key: "700");
			font-size: 1.5rem;
			font-weight: 600;

			&:hover {
				background-color: map-get($map: $purpleMap, $key: "700");
				color: #ffffff;
				box-shadow: map-get($map: $shadows, $key: "pronounced");
				cursor: pointer;
			}
		}
	}
}
