@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.EditFacilityInfo {
	@include fill_container;
	padding-bottom: 5rem;
	margin-bottom: 5rem;

	&_header {
		width: auto;
		height: auto;
		@include flex_row(center, flex-start);

		&_badge {
			width: 15rem;
			height: 15rem;
			border-radius: 50%;
			background-color: map-get($map: $blueGreyMap, $key: "800");
			box-shadow: map-get($map: $shadows, $key: "medium");
			@include flex_row(center, center);

			&_icon {
				// margin-top: 1.5rem;
				// margin-right: 0.4rem;
				margin-top: -1.5rem;
				width: 13rem;
				height: 13rem;
				fill: #ffffff;
			}
		}
	}
	&_form {
		display: block;
		margin: 0 auto;
		width: 70%;
		min-width: 35rem;
		max-width: 80rem;
		height: auto;
		padding: 1rem 2rem;
		padding-bottom: 5rem;
		@include flex_col(center, center);

		&_section {
			@include fill_container;

			&_options {
				height: 10rem;
				@include flex_col(space-evenly, space-evenly);
			}

			&_actions {
				width: 100%;
				@include flex_row(flex-start, center);
				margin-top: -2rem;
				margin-bottom: 2rem;

				&_generateTemp {
					padding: 0.8rem 1.4rem;
					border-radius: 0.5rem;
					border: none;
					outline: none;
					color: map-get($map: $purpleMap, $key: "700");
					font-size: 1.6rem;
					font-weight: 500;
					background-color: transparent;
					&:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
				&_copyTemp {
					padding: 0.8rem 1.4rem;
					border-radius: 0.5rem;
					border: none;
					outline: none;
					color: map-get($map: $greenMap, $key: "500");
					font-size: 1.6rem;
					font-weight: 500;
					background-color: transparent;
					&:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}

			&_multiInput {
				width: 100%;
				height: auto;
				min-height: 8rem;
				@include flex_row(flex-start, center, wrap);

				&_city {
					width: 33.33%;
					height: 100%;
					@include flex_row(flex-start, flex-end);
					flex-shrink: 2;
				}
				&_state {
					width: 33.33%;
					height: 100%;
					@include flex_row(center, flex-end, wrap);
					flex-shrink: 2;
				}
				&_zip {
					width: 33.33%;
					height: 100%;
					@include flex_row(flex-end, flex-end, wrap);
					flex-shrink: 2;
				}
			}
		}
		&_header {
			width: 100%;
			height: auto;
			padding: 1rem 2rem;
			margin-bottom: 2rem;

			&_title {
				font-size: 1.8rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");
			}
		}

		&_actions {
			width: 100%;
			height: auto;
			@include flex_row(flex-end, center);
		}
	}
}

@media only screen and (max-width: 1100px) {
	.EditFacilityInfo {
		&_form {
			@include flex_col(center, center);

			&_section {
				@include fill_container;

				&_multiInput {
					@include flex_col(flex-start, flex-start, wrap);
					margin-bottom: 1rem;

					&_city {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-start);
					}
					&_state {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-end);
					}
					&_zip {
						width: 100%;
						height: 100%;
						@include flex_row(flex-start, flex-end);
					}
				}
			}
		}
	}
}
