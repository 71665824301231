@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ExportView {
	width: 100%;
	height: auto;

	&_header {
		width: 100%;
		height: auto;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "800");
		}
	}
}
