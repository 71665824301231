@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.MaintenanceMode {
	@include fill_container;

	&_header {
		width: 100%;
		height: auto;
		min-height: 20vh;
		@include flex_col(center, center);

		&_icon {
			width: 6rem;
			height: 6rem;
			fill: map-get($map: $yellowMap, $key: "400");
		}

		&_title {
			font-size: 3rem;
			font-weight: 600;
			color: map-get($map: $redMap, $key: "600");
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 500;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
	&_nav {
		width: 100%;
		@include flex_row(center, center);

		&_goBack {
			width: 8rem;
			height: 8rem;
			border-radius: 50%;
			@include flex_col(center, center);
			&:hover {
				box-shadow: map-get($map: $hoverShadows, $key: "bright");
				cursor: pointer;
				background-color: map-get($map: $purpleMap, $key: "600");
			}
			&:hover &_icon {
				fill: map-get($map: $purpleMap, $key: "100");
				cursor: pointer;
			}
			&:hover &_label {
				color: map-get($map: $purpleMap, $key: "100");
				cursor: pointer;
			}

			&_icon {
				width: 3.5rem;
				height: 3.5rem;
				fill: map-get($map: $blueGreyMap, $key: "600");
				cursor: pointer;
			}
			&_label {
				font-size: 1.4rem;
				font-weight: 600;
				color: map-get($map: $blueGreyMap, $key: "600");
			}
		}
	}

	&_children {
		@include fill_container;
	}
}
