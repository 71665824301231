@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.ErrorIndicator {
	width: fit-content;
	height: auto;
	@include flex_col(center, center);

	&_top {
		width: 100%;
		height: auto;
		@include flex_row(flex-start, center);
		&_iconWrapper {
			width: 8rem;
			height: 8rem;
			fill: map-get($map: $redMap, $key: "200");
			@include flex_row(center, center);
			margin-right: 1rem;

			&_icon {
				width: 6rem;
				height: 6rem;
				fill: map-get($map: $redMap, $key: "600");
			}
		}
		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $redMap, $key: "600");
		}
	}
	&_main {
		@include fill_container;
	}
}
