@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CommunityActivitySummary {
	@include fill_container;

	&_section {
		padding: 4rem 2rem;
		font-size: 1.5rem;
		font-weight: 600;
	}
}

.SummaryDesc {
	width: fit-content;
	height: fit-content;
	padding: 1.5rem 1.5rem;
	border-radius: 5rem;
	@include flex_row(flex-start, center);
}

.ActivityDesc {
	width: fit-content;
	height: fit-content;
	padding: 1.5rem 1.5rem;
	border-radius: 5rem;
	@include flex_row(flex-start, center);

	&_iconWrapper {
		width: 4rem;
		height: 4rem;
		min-width: 4rem;
		border-radius: 50%;
		background-color: map-get($map: $greenMap, $key: "200");
		@include flex_row(center, center);
		box-shadow: map-get($map: $shadows, $key: "medium");
		// border: 1px solid map-get($map: $greenMap, $key: "500");

		&_icon {
			width: 3rem;
			height: 3rem;
			fill: map-get($map: $greenMap, $key: "500");
			margin-right: 0.05rem;
		}
	}

	&_desc {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $greenMap, $key: "600");
		margin-left: 1rem;
	}
}
.NoActivityDesc {
	width: fit-content;
	height: fit-content;
	padding: 1.5rem 1.5rem;
	border-radius: 5rem;
	@include flex_row(flex-start, center);

	&_iconWrapper {
		width: 4rem;
		height: 4rem;
		min-width: 4rem;
		border-radius: 50%;
		background-color: map-get($map: $redMap, $key: "200");
		@include flex_row(center, center);
		box-shadow: map-get($map: $shadows, $key: "medium");

		&_icon {
			margin-bottom: 0.5rem;
			width: 2.5rem;
			height: 2.5rem;
			fill: map-get($map: $redMap, $key: "600");
		}
	}

	&_desc {
		font-size: 1.8rem;
		font-weight: 600;
		color: map-get($map: $redMap, $key: "600");
		margin-left: 1rem;
	}
}
