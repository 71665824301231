@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FileCard {
	width: 25rem;
	max-width: 25rem;
	height: 25rem;
	max-height: 25rem;
	border-radius: 1rem 1rem;
	background-color: #ffffff;
	text-decoration: none;
	border-top: 10px solid map-get($map: $greenMap, $key: "500");
	margin-bottom: 6rem;

	&:hover {
		cursor: pointer;
		box-shadow: map-get($map: $shadows, $key: "heavy");
	}
	&:hover &_wrapper_info_name {
		cursor: pointer;
		color: map-get($map: $blueMap, $key: "600");
	}
	&:hover &_wrapper_info_size {
		cursor: pointer;
		color: map-get($map: $blueMap, $key: "600");
	}

	&_wrapper {
		position: relative;
		width: 25rem;
		height: 25rem;
		// max-width: 25rem;
		// max-height: 25rem;
		max-width: 25rem;
		max-height: 25rem;
		text-decoration: none;

		&_img {
			width: 25rem;
			height: 20rem;
			padding: 1rem 1rem;
			background-color: #ffffff;
			border-radius: 1rem 1rem 0 0;
		}
		&_info {
			width: 100%;
			height: 5rem;
			padding: 0.5rem;
			border-radius: 0 0 1rem 1rem;
			background-color: #ffffff;
			text-decoration: none;

			&_name {
				font-size: 1.4rem;
				font-weight: 600;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "900");
				text-decoration: none;
				&:hover {
					cursor: pointer;
					color: map-get($map: $blueMap, $key: "600");
				}
			}
			&_size {
				font-size: 1.3rem;
				font-weight: 500;
				font-family: map-get($map: $fonts, $key: "altSans");
				color: map-get($map: $blueGreyMap, $key: "500");
				text-decoration: none;
			}
		}
	}
}
