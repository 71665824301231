@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.DemoPage {
	@include fill_container;
	background-color: map-get($map: $blueGreyMap, $key: "900");
	min-height: auto;

	&_header {
		width: 100%;
		height: auto;
		padding: 2rem;

		&_title {
			font-size: 3rem;
			font-weight: 700;
			color: map-get($map: $blueGreyMap, $key: "100");
		}
	}
	&_main {
		@include fill_container;
		padding: 2rem;
		background-color: transparent;
	}
}
