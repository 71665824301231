@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.FullPageTabPanels {
	width: 100%;
	min-width: 100%;
	height: auto;
	padding: 1rem;
	background-color: transparent;
}
